/*--------------------------------------------------------------
# Header styles
--------------------------------------------------------------*/

$brand-dark: #19233C;
$brand-orange: #FF7D45;

$brand-transition: .2s ease-in-out;

$font-18: 1.125rem;

.header {
    background-color: #fff;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 5;

    figure {
        img {
            height: auto;
            max-width: 8.75rem;
            width: 100%;
        }
    }

    .navbar-toggler {
        appearance: none;
        background-color: $brand-dark;
        border: none;
        border-radius: 4px;
        height: 3.75rem;
        width: 3.75rem;

        &-icon {
            background-color: #fff;
            display: inline-block;
            height: 0.125rem;
            position: relative;
            top: -2px;
            transition: background 0.3s 0s ease-in-out;
            width: 1.75rem;

            &::before,
            &::after {
                background-color: #fff;
                content: '';
                display: inline-block;
                height: 0.125rem;
                left: 0;
                position: absolute;
                transform-origin: 14px center;
                transition: top 0.3s 0.6s ease-in-out, transform 0.3s ease-in-out, background 0.3s 0s ease-in-out;
                width: 1.75rem;
            }

            &::before {
                top: 0.5rem;
            }

            &::after {
                top: -0.5rem;
            }
        }
    }

    .navbar {
        @media screen and (max-width: 991px) {
            position: absolute;
            transform: scaleY(0);
            transform-origin: top;
            transition: transform $brand-transition;
            translate: 0 67px;
            z-index: 5;

            //make mobile menu full width
            left: 50%;
            margin-left: -50vw;
            width: 100vw;
        }

        ul {
            @media screen and (max-width: 991px) {
                width: 100%;

                li {
                    a {
                        background-color: $brand-orange;
                        color: #fff;
                        display: block;
                        font-size: 1.375rem;
                        font-weight: 300;
                        padding: 1.5rem;
                        text-decoration: none;

                        &.active {
                            background-color: $brand-dark;
                        }
                    }
                }
            }

            @media screen and (min-width: 992px) {
                column-gap: 80px;

                a {
                    font-size: $font-18;
                    text-underline-offset: 0.188rem;
                    transition: color $brand-transition, text-decoration-color $brand-transition;

                    &.active {
                        color: $brand-orange;
                    }

                    &:not(:hover) {
                        text-decoration-color: transparent;
                    }

                    &:hover {
                        color: $brand-orange;
                        text-decoration-color: currentColor;
                    }
                }
            }
        }
    }

    .navbar.is-active {
        @media screen and (max-width: 991px) {
            transform: scaleY(1);
    
            + .navbar-toggler .navbar-toggler-icon {
                background: transparent !important;
                transition: background 0.3s 0s ease-in-out;
        
                &:before,
                &:after {
                    transition: top 0.3s ease-in-out,transform 0.3s 0.5s ease-in-out;
                    top: 0;
                }
        
                &:before {
                    transform: rotate(45deg);
                }
        
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .btn-link {
        width: fit-content;

        @media screen and (max-width: 991px) {
            margin-top: 1.5rem;
        }

        span {
            transition: color $brand-transition;
        }

        svg {
            translate: 0.5rem -2px;

            path {
                transition: stroke $brand-transition;
            }
        }
    
        &:hover {
            span {
                color: #fff;
            }

            svg path {
                stroke: #fff;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    body.is-scrolling {
        figure.header__logo,
        figure.header__logo img,
        .btn-link.header__btn {
            display: none !important;
        }
    }
}