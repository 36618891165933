/*--------------------------------------------------------------
# Footer styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-dark: #19233C;
$brand-orange: #FF7D45;

$brand-transition: .2s ease-in-out;

.footer {
    background-color: $brand-grey;
    padding: 3.125rem 0;

    @media screen and (min-width: 676px) {
        padding: 3.125rem 0;
    }

    @media screen and (min-width: 992px) {
        padding: 5.625rem 0;
    }

    .row {
        row-gap: 1.5rem;
    }

    figure {
        @media screen and (max-width: 991px) {
            display: flex;
            justify-content: center;
        }

        img {
            height: auto;
            max-width: 25.625rem;
            margin-bottom: 1rem;
            width: 100%;
    
            @media screen and (min-width: 992px) {
                max-width: 8.75rem;
            }
        }
    }

    svg {
        circle {
            transition: fill $brand-transition;
        }

        &:hover {
            circle {
                fill: $brand-dark;
            }
        }
    }

    &__text,
    &__title {
        @media screen and (max-width: 991px) {
            text-align: center;
        }
    }

    &__text {
        + a {
            margin-bottom: 0.313rem;
            margin-top: 1.875rem;
        }
    }

    &__title {
        color: $brand-orange;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1.25rem;
    }

    a:not(ul li a) {
        @media screen and (max-width: 991px) {
            text-align: center;
        }
    }

    ul {
        li {
            &:not(:first-child) {
                margin-top: 0.625rem;
            }

            a {
                display: block;
                font-size: 1.063rem;
                padding-left: 1.5rem;
                position: relative;

                &::before {
                    content: '';
                    background-image: url(../images/chevron-right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 1.25rem;
                    left: 0;
                    position: absolute;
                    top: 0.188rem;
                    width: 1.25rem;
                }

                &:not(:hover) {
                    text-decoration: none;
                }
            }
        }
    }
}