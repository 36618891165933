/*--------------------------------------------------------------
# Sticky banner styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-dark: #19233C;
$brand-orange: #FF7D45;
$brand-aqua: #4BE6CB;

$font-18: 1.125rem;

.info-banner {
    background-color: $brand-dark;
    bottom: 0;
    left: 0;
    padding: 1rem 0;
    width: 100%;

    &__text {
        * {
            color: #fff;
        }
        
        p {
            font-size: 0.75rem; // 12 px
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
    
            @media screen and (min-width: 992px) {
                font-size: $font-18;
            }
        }
    }
}