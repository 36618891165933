/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";
// @import "../../../../node_modules/tables";
// @import "../../../../node_modules/forms";
// @import "../../../../node_modules/buttons";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/button-group";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/card";
@import "../../../../node_modules/bootstrap/scss/accordion";
// @import "../../../../node_modules/breadcrumb";
// @import "../../../../node_modules/pagination";
// @import "../../../../node_modules/badge";
// @import "../../../../node_modules/alert";
// @import "../../../../node_modules/progress";
@import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/close";
// @import "../../../../node_modules/toasts";
@import "../../../../node_modules/bootstrap/scss/modal";
// @import "../../../../node_modules/tooltip";
// @import "../../../../node_modules/popover";
// @import "../../../../node_modules/carousel";
// @import "../../../../node_modules/spinners";
@import "../../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../../node_modules/bootstrap/scss/utilities/api";
