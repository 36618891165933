/*--------------------------------------------------------------
# Button styles
--------------------------------------------------------------*/

$brand-dark: #19233C;
$brand-aqua: #4BE6CB;

$brand-transition: .2s ease-in-out;

$font-18: 1.125rem;

.btn-link {
    appearance: none;
    background-color: $brand-aqua;
    border: none;
    border-radius: 6.25rem;
    color: $brand-dark;
    font-size: $font-18;
    font-weight: 600;
    padding: 0.75rem 1.875rem;
    text-decoration: none;
    transition: background-color $brand-transition, color $brand-transition;

    &:hover {
        background-color: $brand-dark;
        color: #fff;
    }

	&.btn-link--dark {
		background-color: $brand-dark;
		color: #fff;

		&:hover{
			background-color: $brand-aqua;
			color: $brand-dark;
		}

	}
}