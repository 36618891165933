/*--------------------------------------------------------------
# Hero styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;

.hero {
    background-color: $brand-grey;

    .container {
        @media screen and (min-width: 992px) {
            margin-top: -6.25rem;
        }
    }

    figure {
        img {
            width: 100%;
        }
    }

    h1 {
        font-size: 1.938rem;
        font-weight: 700;
        line-height: 128%;
        margin-bottom: 0.938rem;

        @media screen and (min-width: 992px) {
            font-size: 2.875rem;
        }
    }
}