/*--------------------------------------------------------------
# Section styles - the logic is top down, so a section has
# spacing at the top but not at the bottom
--------------------------------------------------------------*/
$block-spc-mob: 1rem;
$block-spc-tab: 2.375rem;
$block-spc-desk: 4.063rem;

$brand-grey: #F5F5F5;
$brand-dark: #19233C;
$brand-orange: #FF7D45;

$font-18: 1.125rem;

.block {
    position: relative;

    // if block has background color give padding top and bottom
    &--spc {
        padding: $block-spc-mob 0;

        @media screen and (min-width: 676px) {
            padding: $block-spc-tab 0;
        }

        @media screen and (min-width: 992px) {
            padding: $block-spc-desk 0;
        }
    }

    &--grey {
        background-color: $brand-grey;

        + .block--grey {
            padding-top: 0;
        }
    }

    p,
    a:not(.btn-link) {
        font-size: $font-18;
        font-weight: 300;
        line-height: 150%;
    }

    p {
        line-height: 150%;
        margin-bottom: 1.5rem;
    }

    strong {
        font-weight: 700;
    }

    h2 {
        font-size: 1.938rem;
        font-weight: 700;
        margin-bottom: 1.5rem;

        @media screen and (min-width: 992px) {
            font-size: 2.875rem;
        }
    }

    a:not(.btn-link):not(.lead-gen-form__info a) {
        color: $brand-orange;
        text-underline-offset: 0.188rem;

        &:not(:hover) {
            text-decoration: none;
        }
    }

    // specific block styles
    .block__wrapper {
        background-color: #fff;
        padding: 1.5rem;

        @media screen and (min-width: 992px) {
            padding: 3.75rem;
        }
    }

    &.calculator {
        @media screen and (min-width: 992px) {
            .col-md-6 {
                &:first-child {
                    padding-right: 1.875rem;
                }
                &:last-child {
                    padding-left: 1.875rem;
                }
            }
        }
    }

    &.calc-results {
        .block__wrapper {
            background-color: #fff;
            border-radius: 0.75rem 0.75rem 0 0;
            padding: 1.5rem;

            p:not(ul p) {
                font-size: 1rem;
            }

            @media screen and (min-width: 992px) {
                padding: 3rem;

                ul {
                    margin-bottom: 1.5rem;
                }
            }
        }

        .calc-results__cta {
            background-color: $brand-dark;
            border-radius: 0 0 0.75rem 0.75rem;
            gap: 0.625rem;
			padding: 1.5rem;
			
			@media screen and (min-width: 992px) {
				padding: 1.5rem 3rem;
			}

            h3 {
                color: #fff;
                font-size: 1.25rem;
                font-weight: 700;
                margin-bottom: 0.625rem;

                @media screen and (min-width: 992px) {
                    font-size: 1.563rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    &.calculator,
    &.lead-gen {
        .block__wrapper {
            border-radius: 0.75rem;
        }
    }
}

p, h1, h2, h3, h4, h5, h6, ul, ol, span, a, strong {
    color: $brand-dark;
}