/*--------------------------------------------------------------
# Results styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-mid-grey: #E6E6E6;
$brand-dark: #19233C;

$font-18: 1.125rem;

$brand-transition: .2s ease-in-out;

// results of the calculator
.calc-results {
    display: none;

    &.show-results {
        display: block;
    }

    h2 {
        font-size: 1.938rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
    }

    ul {
        li {
            background-color: $brand-grey;
            border-radius: 0.5rem;
            font-size: $font-18;
            padding: 0.5rem 1rem;

            + li {
                margin-top: 1rem;
            }

            p {
                + strong {
                    margin-left: 0.625rem;
                }
            }
        }
    }

    p {
        font-weight: 300;
    }

    strong {
        font-weight: 700;
    }
}


// results of the lead gen form
.lead-gen-results {
    .col-md-10 + .col-md-10 {
        margin-top: 1.5rem;
    }

    h1 {
        font-size: 39px;
        font-weight: 700;
        margin-bottom: 26px;

        @media screen and (min-width: 992px) {
            font-size: 2.875rem;
        }
    }

    &__more {
        margin-top: 1.25rem;

        button.lead-gen-results__btn {
            appearance: none;
            background-color: $brand-mid-grey;
            border: none;
            border-radius: 0.75rem;
            padding: 1rem 2rem;

            span {
                font-size: 1.25rem;
                font-weight: 700;

                @media screen and (min-width: 992px) {
                    font-size: 1.563rem;
                }
            }

            img {
                transition: rotate $brand-transition;
            }

            &[aria-expanded=true] {
                img {
                    rotate: -180deg;
                }
            }

			@media screen and (max-width: 991px) {
				text-align: left;
			}
        }

        #more-brokers-inner {
            margin-top: 1.875rem;

            + .more-brokers {
                margin-top: 1.5rem;
            }
        }
    }
}