/*--------------------------------------------------------------
# Broker card styles
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-mid-grey: #E6E6E6;
$brand-dark-grey: #A5AAAA;
$brand-dark: #19233C;
$brand-orange: #FF7D45;
$brand-aqua: #4BE6CB;
$brand-blue: #4F85FF;

$brand-transition: .2s ease-in-out;

$font-18: 1.125rem;

.broker-card {
    figure {
        align-items: center;
        aspect-ratio: 5/4;
        background-color: #fff;
        border-radius: 0.75rem;
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        img {
            height: auto;
            padding: 1rem;
            width: 100%;
        }
    }

    &__text {
        margin-top: 1.875rem;

        h2 {
            font-size: 1.563rem;
            margin-bottom: 0.5rem;

            @media screen and (min-width: 992px) {
                font-size: 1.938rem;
            }
        }
    }

    &--featured {
        .broker-card__text,
        .cta,
        .success {
            p, h1, h2, h3, h4, h5, h6, ul, ol, span, a, strong {
                color: #fff;
            }
        }

		.cta {
			a {
				color: $brand-dark;

				&:hover {
					color: #fff;
				}
			}
		}
    }

    &:not(.broker-card--featured) {
        background-color: #fff;
        border-radius: 0.75rem;
        margin-top: 1.5rem;

        figure {
            border: solid 1px $brand-dark-grey;
        }

        .broker-card__cta {
            background-color: $brand-grey;
        }
    }

    &__body {
        padding: 2rem;
    }

    &__cta {
        border-radius: 0.75rem;
        margin-top: 1.875rem;
        padding: 1rem 1rem 1rem 1.5rem;
		
		@media screen and (max-width: 991px) {
			padding: 1rem;
		}

        .cta {
            align-items: center;
            display: flex;
            gap: 1.25rem;
            justify-content: space-between;
            width: 100%;

            @media screen and (max-width: 991px) {
                flex-direction: column;
				text-align: center;
            }

            h3 {
                font-size: 1rem;
                font-weight: 700;
                line-height: 150%;
    
                @media screen and (min-width: 992px) {
                    font-size: 1.25rem;
                }
            }
        }
    }

	&__submit {
		@media screen and (max-width: 991px) {
			width: 100%;
		}
	}

    .success {
        div:not(.success__text) {
            gap: 1rem;
        }

        h3 {
            font-size: 1rem;

            @media screen and (min-width: 992px) {
                font-size: 1.25rem;
            }
        }

        &__text {
            margin-top: 1.25rem;
        }
    }

    &--featured {
        .broker-card__header {
            background-color: $brand-blue;
            border-radius: 0.75rem 0.75rem 0 0;
            padding: 0.5rem 1.5rem;
        }

        .broker-card__body {
            background-color: $brand-dark;
            border-radius: 0 0 0.75rem 0.75rem;
            color: #fff;
        }

        .broker-card__cta {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }
}