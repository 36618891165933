/*--------------------------------------------------------------
# Any miscellaneous styles that are used globally
--------------------------------------------------------------*/

$brand-grey: #F5F5F5;
$brand-dark: #19233C;
$brand-orange: #FF7D45;
$brand-aqua: #4BE6CB;

* {
    font-family: 'Outfit', sans-serif;
}

table {
	margin: 0 auto;
	color: #333;
	background: white;
	border: 1px solid grey;
	font-size: 12pt;
	border-collapse: collapse;
	width: 100%;
}

table thead th,
table tfoot th {
	color: #777;
	background: rgba(0, 0, 0, .1);
}

table th,
table td {
	padding: .5em;
	border: 1px solid lightgrey;
}

h1 > a {
	font-size:15px;
	vertical-align: middle;
}

